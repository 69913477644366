import { Box, Card, CardContent, Divider, Typography, Grid, Button } from '@material-ui/core';

import { B2BOrderSummaryPayout } from '../../types/b2bPayout';
import { formatDinero, generateDinero, getDinero } from '../../utilities';
import { OrderPayloadGiftCardProduct } from '../../types';
import { manageUserEditFormUseStyles } from '../manage-user/editForm';
import { computeMerchantOrderPayoutDetails } from '../../utilities/order.util';

export enum PayoutTypeEnum {
    UNPAID = 'UNPAID',
    PAID = 'PAID'
}

type Props = {
    order: B2BOrderSummaryPayout;
    orderItems: OrderPayloadGiftCardProduct[];
    payoutType: PayoutTypeEnum;
    payout?: B2BOrderSummaryPayout['payout'];
    onCancel: () => void;
};

const OrderDetails = (props: Props) => {
    const classes = manageUserEditFormUseStyles();

    const {
        promoCodeDiscount,
        orderItemsTotal,
        grandTotal,
        orderFee,
        paymentGatewayFee,
        totalGiftCardFees,
        amountToPay
    } = computeMerchantOrderPayoutDetails(props.order, props.orderItems);

    return (
        <Card className={classes.form}>
            <CardContent>
                <Typography component="h6" variant="h6" className="headingFont" align="left">
                    Order Payout Details
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
                    <Typography component="strong" variant="subtitle2">
                        Order ID
                    </Typography>
                    <Typography component="strong" variant="subtitle2">
                        #{props.order.orderId}
                    </Typography>
                </Box>
                {props.payoutType === PayoutTypeEnum.PAID && (
                    <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography component="strong" variant="subtitle2">
                            Merchant User Id
                        </Typography>
                        <Typography component="strong" variant="subtitle2">
                            #{props.order.businessUserId}
                        </Typography>
                    </Box>
                )}
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography component="strong" variant="subtitle2">
                        Card Value
                    </Typography>
                    <Typography component="strong" variant="subtitle2">
                        {formatDinero(orderItemsTotal)}
                    </Typography>
                </Box>
                {(props.order.promoCode && promoCodeDiscount.getAmount() > 0 && (
                    <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Promo Code ({props.order.promoCode.key})
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            -{formatDinero(promoCodeDiscount)}
                        </Typography>
                    </Box>
                )) ||
                    null}
                { grandTotal.getAmount() < orderItemsTotal.getAmount() && (<Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography component="strong" variant="subtitle2">
                        Remaining Card Value
                    </Typography>
                    <Typography component="strong" variant="subtitle2">
                        {formatDinero(grandTotal)}
                    </Typography>
                </Box>)}

                <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2" color="textSecondary">
                        Order Fee
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        -{formatDinero(orderFee)}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2" color="textSecondary">
                        Gift Card Fees ({props.order.subscription.giftCardFee}%)
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        -{formatDinero(totalGiftCardFees)}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2" color="textSecondary">
                        Payment Gateway Fee
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        -{formatDinero(paymentGatewayFee)}
                    </Typography>
                </Box>

                <Box my={1}>
                    <Divider />
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2" className="headingFont">
                        Payout Amount
                    </Typography>
                    <Typography variant="subtitle2" className="headingFont">
                        {formatDinero(amountToPay)}
                    </Typography>
                </Box>
                {props.payoutType === PayoutTypeEnum.PAID && props.payout?.paidAmount && (
                    <>
                        <Box my={1}>
                            <Divider />
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6" className="subtitle1">
                                Amount Paid through Payout
                            </Typography>
                            <Typography variant="h6" className="headingFont">
                                #{props.payout?.id}
                            </Typography>
                        </Box>
                    </>
                )}
                <Grid item={true} xs={12} className={classes.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            props.onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OrderDetails;
