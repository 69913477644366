import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CachedIcon from '@material-ui/icons/Cached';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Switch } from '@material-ui/core';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import { ErrorType, UserAccount } from '../../types';
import { joinNameParts } from '../../utilities';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import UserAvatar from '../common/user-avatar';
import ManageBusinessUserEditForm from './editForm';
import StatusButton from '../common/status-button';
import storeContext from '../../contexts/store-context';
import { useStyles } from '../manage-user';

type State = {
    isRefreshing: boolean;
    isLoading: boolean;
    renderEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeUser: UserAccount | null;
    selectedUserIds: Array<number>;
    users: Array<UserAccount>;
};

const ManageBusinessUser = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, manageBusinessUserAction, manageUserAction } = storeContext();

    const canRead = HasPermission(userService.user, 'APPROVE_BUSINESS_USER', 'READ');
    const canUpdate = HasPermission(userService.user, 'APPROVE_BUSINESS_USER', 'UPDATE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllBusinessUsers();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        isLoading: false,
        isRefreshing: false,
        renderEditForm: false,
        menuAnchorEle: null,
        activeUser: null,
        selectedUserIds: []
    });

    const getAllBusinessUsers = async (isRefreshing?: boolean) => {
        setState((prevState) => ({ ...prevState, isLoading: !isRefreshing, isRefreshing: !!isRefreshing }));
        const users = await manageBusinessUserAction()?.getAllBusinessUsers();
        setState((prevState) => ({ ...prevState, isLoading: false, isRefreshing: false, users: users || [] }));
    };

    const toggleFraudCheck = (userId: number) => {
        manageUserAction()?.updateUserFraudCheck(userId);
        setState((prevState) => ({
            ...prevState,
            users: prevState.users.map((user) => {
                if (user.userId === userId) {
                    return {
                        ...user,
                        checkFraud: !user.checkFraud
                    };
                }
                return user;
            })
        }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'userId', label: 'User Id' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'companyName', label: 'Company Name' },
        { id: 'checkFraud', label: 'Check Fraud' },
        { id: 'status', label: 'Status', align: 'left' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = state.users
        .sort((a, b) => ((b?.userId || 0) > (a?.userId || 0) ? 1 : -1))
        .map((user) => {
            const name = joinNameParts(user) || '-';

            return {
                id: {
                    text: user.userId?.toString() || ''
                },
                userId: {
                    text: `#${user.userId?.toString()}` || '',
                    align: 'left'
                },
                name: {
                    align: 'left',
                    text: name,
                    element: (
                        <Grid item={true} className={classes.userAvatarOuter}>
                            <UserAvatar className={classes.userAvatar} user={user} />
                            <Typography>{name}</Typography>
                        </Grid>
                    )
                },
                email: {
                    align: 'left',
                    text: user.email || '-'
                },
                companyName: {
                    align: 'left',
                    text: user.companyName || '-'
                },
                checkFraud: {
                    align: 'left',
                    text: '',
                    element: (
                        <Switch
                            checked={user.checkFraud}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleFraudCheck(user.userId!);
                            }}
                            color="primary"
                        />
                    )
                },
                status: {
                    align: 'left',
                    text: '',
                    element: (
                        <StatusButton
                            options={[
                                {
                                    type: 'APPROVAL_PENDING',
                                    text: 'Pending',
                                    color: 'warning'
                                },
                                {
                                    type: 'REVOKED',
                                    text: 'REVOKED',
                                    color: 'danger'
                                },
                                {
                                    type: 'ON_WATCH',
                                    text: 'ON WATCH',
                                    color: 'warning'
                                }
                            ]}
                            type={user.accessStatus}
                        />
                    )
                },
                options: {
                    align: 'right',
                    text: '',
                    helperText:
                        (user.updatedBy?.userId &&
                            `updated by - ${
                                user.updatedBy?.firstName ? joinNameParts(user.updatedBy) : user.updatedBy?.email || ''
                            }${user.updatedBy?.updatedAt && ' - '}${
                                user.updatedBy?.updatedAt && moment(new Date(user.updatedBy?.updatedAt)).fromNow()
                            }`) ||
                        undefined,
                    element:
                        (canUpdate && (
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setState((prevState) => ({
                                        ...prevState,
                                        menuAnchorEle: e.currentTarget,
                                        activeUser: user
                                    }));
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )) ||
                        undefined
                }
            };
        });

    const onSelectedHeaderComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={(e) => approveAll()}
                size="small"
                color="inherit"
                variant="contained"
                className={classes.approveButton}
            >
                <ThumbUpIcon />
                &nbsp;Approve
            </Button>

            <Button
                onClick={(e) => rejectAll()}
                size="small"
                color="inherit"
                variant="contained"
                className={classes.rejectButton}
            >
                <ThumbDownIcon />
                &nbsp;Revoke
            </Button>
        </Grid>
    );

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Business Users</Typography>
            <Button
                onClick={(e) => getAllBusinessUsers(true)}
                size="small"
                variant="contained"
                disabled={state.isRefreshing}
            >
                <CachedIcon className={(state.isRefreshing && 'rotation-loading') || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    );

    const handleRowClick = (userId: string) => {
        if (!canUpdate) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderEditForm: true,
            activeUser: prevState.users.find((u) => u.userId === +userId) || null
        }));
    };

    const updateUsers = async (users: Array<Partial<UserAccount>>) => {
        const updatedUsers = await manageBusinessUserAction()?.updateBusinessUsers(users);
        setState((prevState) => ({ ...prevState, users: updatedUsers || [] }));

        return null;
    };

    const approveAll = () => {
        updateUsers(
            state.users
                .filter((u) => state.selectedUserIds.includes(u.userId || -1))
                .map((u) => ({ ...u, accessStatus: 'APPROVED' }))
        );
    };

    const rejectAll = () => {
        updateUsers(
            state.users
                .filter((u) => state.selectedUserIds.includes(u.userId || -1))
                .map((u) => ({
                    ...u,
                    accessUpdateReason: null,
                    accessStatus: 'REVOKED'
                }))
        );
    };

    const onCheckboxSelect = (selectedRows: TableResultRow[]) =>
        setState((prevState) => ({ ...prevState, selectedUserIds: selectedRows.map(({ id }) => +id.text) }));

    const handleMenuClose = () => {
        setState((prevState) => ({ ...prevState, menuAnchorEle: null, activeUser: null }));
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {(state.activeUser && state.renderEditForm && (
                    <ManageBusinessUserEditForm
                        user={state.activeUser}
                        onCancel={() =>
                            setState((prevState) => ({ ...prevState, renderEditForm: false, activeUser: null }))
                        }
                        onUpdate={updateUsers}
                    />
                )) ||
                    null}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem
                        disabled={!canUpdate}
                        onClick={() => {
                            if (state.activeUser) {
                                handleRowClick((state.activeUser?.userId || -1).toString());
                            }
                            setState((prevState) => ({ ...prevState, menuAnchorEle: null }));
                        }}
                    >
                        Edit
                    </MenuItem>
                </Menu>

                <TableComponent
                    rowHover={canUpdate}
                    headerComponent={headerComponent}
                    onSelectedHeaderComponent={(tableRows.length && onSelectedHeaderComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    rowTooltip={(canUpdate && 'Click to edit') || undefined}
                    onRowClick={handleRowClick}
                    onCheckboxSelect={onCheckboxSelect}
                    overrideClasses={{
                        toolbarClass: classes.toolbar
                    }}
                />
            </Container>
        </Grid>
    );
};

export default ManageBusinessUser;
