import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteIcon from '@material-ui/icons/Delete';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import storeContext from '../../contexts/store-context';
import { EGifterCardTypeEnum, SavedProducts } from '../../types/savedProducts';
import { EGifterProduct } from '../../types/eGifter';
import ConfirmationDialog from '../common/confirmation-dialog';
import { ErrorType } from '../../types';
import FilterCountries from './filter-countries';
import CategoryFilter, { useCategoryFilter } from '../common/category-filter';
import SyncB2BGiftCardTranslations from './sync-b2b-gift-card-translations';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const useStyles = makeStyles((theme) => ({
    userAvatarOuter: {
        display: 'flex',
        alignItems: 'center',
        '& $userAvatar': {
            marginRight: 16
        }
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: 20,
        color: theme.palette.primary.main
    },
    toolbar: {
        textAlign: 'left'
    },
    menuItem: {
        '&$approveButton': {
            color: theme.palette.success.main,
            background: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.success.dark
            }
        },
        '&$rejectButton': {
            color: theme.palette.error.main,
            background: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.error.dark
            }
        }
    },
    headerComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px'
    },
    filter: {
        width: 200
    },
    userAvatar: {}
}));

type State = {
    isLoading: boolean;
    isHeaderButtonLoading: boolean;
    isConfirmationDialogOpen: boolean;
    dialogType: 'SAVE' | 'REMOVE';
    renderEditForm: boolean;
    activeProduct: string | null;
    selectedProductIds: Array<string>;
    allProducts: Array<EGifterProduct>;
    productsSavedInDB: Array<SavedProducts>;
};

const ManageProduct = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, giftCardAction } = storeContext();

    const { giftCardType, countryCode, onCountryCodeChange, onGiftCardTypeChange } = useCategoryFilter();

    const canRead = HasPermission(userService.user, 'MANAGE_PRODUCT', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_PRODUCT', 'UPDATE');

    const [state, setState] = useState<State>({
        allProducts: [],
        isHeaderButtonLoading: false,
        isConfirmationDialogOpen: false,
        productsSavedInDB: [],
        isLoading: false,
        renderEditForm: false,
        dialogType: 'REMOVE',
        activeProduct: null,
        selectedProductIds: []
    });

    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
    }, []);

    useEffect(() => {
        getAllProducts();
    }, [giftCardType, countryCode]);

    const getAllProducts = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const [apiResponse, products] = await Promise.all([
                apiService.get(`/api/admin/manage-product/get-all?giftCardType=${giftCardType}`),
                giftCardType === 'B2C'
                    ? giftCardAction()?.getAllGiftCards({ showAll: true, forceFetch: true })
                    : giftCardAction()?.getAllB2BGiftCards({
                          showAll: true,
                          forceFetch: true,
                          cultureCode: `en-${countryCode}`
                      })
            ]);
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }
            setState((prevState) => ({
                ...prevState,
                allProducts: products || [],
                productsSavedInDB: response.data as SavedProducts[]
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'option', label: '' }
    ];

    const savedTableRows: Array<TableResultRow> = state.allProducts
        .filter((p) => state.productsSavedInDB.find((ps) => ps.productId === p.id))
        .map((p) => {
            const productFound = state.productsSavedInDB.find((ps) => ps.productId === p.id);

            return {
                id: {
                    align: 'left',
                    text: p.id?.toString() || ''
                },
                name: {
                    align: 'left',
                    text: p.name || '-'
                },
                option: {
                    align: 'right',
                    text: '',
                    element: canUpdate ? (
                        <Button
                            startIcon={<DeleteIcon />}
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                productFound &&
                                setState((prevState) => ({
                                    ...prevState,
                                    dialogType: 'REMOVE',
                                    isConfirmationDialogOpen: true,
                                    activeProduct: productFound.productId
                                }))
                            }
                        >
                            Remove From DB
                        </Button>
                    ) : (
                        <></>
                    )
                }
            };
        });

    const notInDBheadCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'option', label: '' }
    ];

    const notInDBTableRows: Array<TableResultRow> = state.allProducts
        .filter((p) => !state.productsSavedInDB.some((ps) => ps.productId === p.id))
        .map((p) => {
            return {
                id: {
                    align: 'left',
                    text: p.id?.toString() || ''
                },
                name: {
                    align: 'left',
                    text: p.name || '-'
                },
                option: {
                    align: 'right',
                    text: '',
                    element: canUpdate ? (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    dialogType: 'SAVE',
                                    isConfirmationDialogOpen: true,
                                    activeProduct: p.id
                                }))
                            }
                        >
                            Save To DB
                        </Button>
                    ) : (
                        <></>
                    )
                }
            };
        });

    const handleRowClick = (id: string) => {
        if (!canUpdate) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            activeUser: prevState.allProducts.find((u) => u.id === id) || null
        }));
    };

    const onCheckboxSelect = (selectedRows: TableResultRow[]) =>
        setState((prevState) => ({ ...prevState, selectedProductIds: selectedRows.map((data) => data.id.text) }));

    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
    };

    const saveToDB = async (productIds: string[]) => {
        try {
            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: true }));

            const data: { productId: string; name: string }[] = productIds.map((pId) => {
                const product = state.allProducts.find((p) => p.id === pId);

                return {
                    productId: product?.id as string,
                    name: product?.name as string,
                    giftCardType,
                    countryCode
                };
            });

            await apiService.post(`/api/admin/manage-product/create-all`, data);

            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: false, isConfirmationDialogOpen: false }));
            getAllProducts();
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: false, isConfirmationDialogOpen: false }));
        }
    };

    const removeFromDB = async (productIds: string[], giftCardType: typeof EGifterCardTypeEnum[number]) => {
        try {
            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: true }));
            await apiService.post(`/api/admin/manage-product/delete-all`, { productIds, giftCardType });
            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: false, isConfirmationDialogOpen: false }));
            getAllProducts();
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isHeaderButtonLoading: false, isConfirmationDialogOpen: false }));
        }
    };

    const onDialogConfirm = () => {
        if (!state.activeProduct) {
            return;
        }

        if (state.dialogType === 'REMOVE') {
            removeFromDB([state.activeProduct], giftCardType);
        } else if (state.dialogType === 'SAVE') {
            saveToDB([state.activeProduct]);
        }
    };

    const headerComponent = (
        <Grid item={true} xs={12}>
            <Grid item={true} xs={12} className={classes.headerComponent}>
                <CategoryFilter
                    countryCode={countryCode}
                    giftCardType={giftCardType}
                    onCountryCodeChange={onCountryCodeChange}
                    onGiftCardTypeChange={onGiftCardTypeChange}
                />
            </Grid>
        </Grid>
    );

    const onSelectedHeaderComponentInDB = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                disabled={state.isHeaderButtonLoading}
                onClick={() => removeFromDB(state.selectedProductIds, giftCardType)}
                size="small"
                color="primary"
                variant="contained"
            >
                Remove from DB
                {state.isHeaderButtonLoading && <CircularProgress className="button-loader" />}
            </Button>
        </Grid>
    );

    const onSelectedHeaderComponentNotInDB = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                disabled={state.isHeaderButtonLoading}
                onClick={() => saveToDB(state.selectedProductIds)}
                size="small"
                color="primary"
                variant="contained"
            >
                Save to DB
                {state.isHeaderButtonLoading && <CircularProgress className="button-loader" />}
            </Button>
        </Grid>
    );

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <Paper>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                        <Tab label="Products in DB" />
                        <Tab label="Products not in DB" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <TableComponent
                            rowHover={canUpdate}
                            showPaginator={{ bottom: true }}
                            headerComponent={headerComponent}
                            onSelectedHeaderComponent={
                                (savedTableRows.length && onSelectedHeaderComponentInDB) || undefined
                            }
                            showSearch={true}
                            isLoading={state.isLoading}
                            rows={savedTableRows}
                            headCells={headCells}
                            fillEmptyRows={false}
                            keyField="id"
                            onRowClick={handleRowClick}
                            onCheckboxSelect={onCheckboxSelect}
                            overrideClasses={{
                                toolbarClass: classes.toolbar
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TableComponent
                            rowHover={canUpdate}
                            headerComponent={headerComponent}
                            onSelectedHeaderComponent={
                                (notInDBTableRows.length && onSelectedHeaderComponentNotInDB) || undefined
                            }
                            showPaginator={{ bottom: true }}
                            showSearch={true}
                            isLoading={state.isLoading}
                            rows={notInDBTableRows}
                            headCells={notInDBheadCells}
                            fillEmptyRows={false}
                            keyField="id"
                            onRowClick={handleRowClick}
                            onCheckboxSelect={onCheckboxSelect}
                            overrideClasses={{
                                toolbarClass: classes.toolbar
                            }}
                        />
                    </TabPanel>
                </Paper>

                <SyncB2BGiftCardTranslations />

                <FilterCountries />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to continue'}
                isLoading={state.isHeaderButtonLoading}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        isConfirmationDialogOpen: false,
                        activeProduct: null
                    }))
                }
                onConfirm={onDialogConfirm}
            />
        </Grid>
    );
};

export default ManageProduct;
