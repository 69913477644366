import React from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import ConfirmationDialog from '../common/confirmation-dialog';
import { ConstantType, TranslationSyncActionLimit } from '../../types';
import moment from 'moment';
import { DateFormatTypeEnum } from '../../utilities';

const SyncB2BGiftCardTranslations = () => {
    const adminClasses = useAdminStyles();
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [isSaving, setIsSaving] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isLoadingActionLimit, setIsLoadingActionLimit] = React.useState(false);
    const [actionLimit, setActionLimit] = React.useState<TranslationSyncActionLimit | null>(null);

    const TRANSLATION_PRICING_LINK = 'https://azure.microsoft.com/en-us/pricing/details/cognitive-services/translator/#pricing';

    const syncTranslationsInDB = async () => {
        setIsSaving(true);
        const apiResponse = await apiService.post(`/api/admin/manage-product/sync-b2b-translations`, {});
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setIsSaving(false);
            closeDialog();
            return;
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        setIsSaving(false);
        closeDialog();
        fetchActionLimit();
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const onConfirm = () => {
        syncTranslationsInDB();
    };

    const fetchActionLimit = async () => {
        setIsLoadingActionLimit(true);
        const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
        const response = apiResponse.parsedBody;
        const constantData: ConstantType = response.data.find(
            (c: ConstantType) => c.key === 'TRANSLATION_SYNC_ACTION_LIMIT'
        );
        setActionLimit(constantData.value as TranslationSyncActionLimit);
        setIsLoadingActionLimit(false);
    };

    React.useEffect(() => {
        fetchActionLimit();
    }, []);

    return (
        <Box my={5}>
            <Paper>
                <Box m={2} py={3} textAlign="left">
                    <Typography align="left" className={adminClasses.heading}>
                        Sync B2B Gift Card Translations
                    </Typography>
                    <Alert severity="info">
                        Click below to sync the English translations for all B2B Gift Cards. This will fetch the latest
                        gift cards from eGifter, translate their descriptions and terms to English and save them to the
                        database.
                        <br /> <br />
                       <strong>Note:</strong>  A maximum of 3 updates is permitted each month.
                    </Alert>

                    {isLoadingActionLimit && <CircularProgress />}
                    {!isLoadingActionLimit && actionLimit && (
                        <Box mt={2} sx={{ letterSpacing: '0.1em'}}>                           
                            <strong>Current month&apos;s updates:</strong> {actionLimit.count}
                            <br />
                            <strong>Last Updated:{' '}</strong>
                            {moment.utc(actionLimit.lastUpdated).format(DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)} UTC
                        </Box>
                    )}

                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="button" onClick={openDialog}>
                            Sync translations
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={
                    <div>
                        <Alert severity="warning">
                            <AlertTitle>NOTE:</AlertTitle>
                            This API is chargeable, check{' '}
                            <a href={TRANSLATION_PRICING_LINK} target="_blank" rel="noreferrer">
                                {TRANSLATION_PRICING_LINK}
                            </a>{' '}
                            for the latest pricing.
                        </Alert>
                        <Box marginTop={1.5}>
                            <Typography>
                                A notification email will be sent upon the completion of the translations.
                                <br/> <br/>
                                Click CONFIRM to continue.
                            </Typography>
                        </Box>
                    </div>
                }
                isLoading={isSaving}
                open={isDialogOpen}
                onClose={closeDialog}
                onConfirm={onConfirm}
            />
        </Box>
    );
};

export default SyncB2BGiftCardTranslations;
