import { ChipTypeMap } from '@material-ui/core';
import { StoreActionProps } from '.';
import { ErrorType, B2BApiConfig, OrderPayloadGiftCardProduct, OrderItemRecipient } from '../types';
import { B2BCustomerGiftCardRedemption } from '../types/b2bCustomerGiftCardRedemption';
import { B2BMerchantCustomerIncentive } from '../types/b2bCustomerIncentive';
import { B2BGiftCardResponseType } from '../types/b2bGiftCard';

export const getFormattedOrderDeliveryType = (
    deliveryType: OrderItemRecipient['deliveryType']
): {
    text: string;
    color: ChipTypeMap['props']['color'];
} => {
    switch (deliveryType) {
        case 'EMAIL':
            return {
                text: 'Email',
                color: 'primary'
            };
        case 'CODES':
            return {
                text: 'CardMoola Account',
                color: 'secondary'
            };
        case 'SMS':
            return {
                text: 'SMS',
                color: 'default'
            };
        default:
            return { text: 'NA', color: 'default' };
    }
};

const getAllGiftCardsByMerchantId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/gift-card`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as B2BGiftCardResponseType[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};


export type SoldB2BGiftCard = OrderPayloadGiftCardProduct & {
    /**
     * use generateDinero()
     */
    balance: number;
    createdAt: string;
    orderId: number;
    recipient: OrderItemRecipient;
    customer: {
        email: string;
        firstName: string;
        lastName: string;
    };
};
const getSoldGiftCards = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/gift-card/sold`);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as SoldB2BGiftCard[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getB2BGiftCardRedemptions = async (props: StoreActionProps, userId: number, orderItemRecipientId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-business-user/${userId}/gift-card/redemptions/${orderItemRecipientId}`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as B2BCustomerGiftCardRedemption[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

export type SentIncentiveGiftCard = B2BMerchantCustomerIncentive & {
    /**
     * use generateDinero()
     */
    balance: number;
    barcodePath: string;
    name: string;
    price: number;
    photo: string;
    expiryDate: string;
};

const getIncentiveGiftCards = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/gift-card/get-incentive-cards`);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as SentIncentiveGiftCard[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getB2BIncentiveCardRedemptions = async (
    props: StoreActionProps,
    userId: number,
    incentiveId: number
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-business-user/${userId}/gift-card/incentive-redemptions/${incentiveId}`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as B2BCustomerGiftCardRedemption[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

export const mapGiftCardPriceDenominations = (priceArray: number[]) => {
    if (!priceArray || !priceArray.length) {
        return '-';
    }
    if (priceArray.length === 1) {
        return '$' + priceArray.toString();
    }
    const firstItem = priceArray[0].toString();
    const lastItem = priceArray[priceArray.length - 1].toString();
    return `$${firstItem} - $${lastItem}`;
};


const b2bGiftCardAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllGiftCardsByMerchantId: (userId: number) => getAllGiftCardsByMerchantId(props, userId),
        getSoldGiftCards: (userId: number) => getSoldGiftCards(props, userId),
        getB2BGiftCardRedemptions: (userId: number, orderItemRecipientId: number) =>
            getB2BGiftCardRedemptions(props, userId, orderItemRecipientId),
        getIncentiveGiftCards: (userId: number) => getIncentiveGiftCards(props, userId),
        getB2BIncentiveCardRedemptions: (userId: number, orderItemRecipientId: number) =>
            getB2BIncentiveCardRedemptions(props, userId, orderItemRecipientId)
    };
};

export default b2bGiftCardAction;

export interface B2BGiftCardAction {
    b2bGiftCardAction: typeof b2bGiftCardAction;
}
