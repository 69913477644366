import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { B2BCustomerGiftCardRedemption } from '../../../types/b2bCustomerGiftCardRedemption';
import {
    DateFormatTypeEnum,
    DateOption,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDateRangeByOptionLabel,
    getDinero
} from '../../../utilities';
import {
    Box,
    Dialog,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles({
    tablePaper: {
        paddingTop: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    },
    notes: {
        maxWidth: 300
    }
});

const headCells: Array<HeadCell> = [
    { id: 'redemptionId', label: '#' },
    { id: 'amount', label: 'Amount' },
    { id: 'balance', label: 'Balance' },
    { id: 'redeemedAt', label: 'Redeemed At' },
    { id: 'notes', label: 'Notes' }
];

const DATE_OPTIONS: DateOption[] = [
    {
        offset: 0,
        startOf: 'day',
        label: 'Today'
    },
    {
        offset: 1,
        startOf: 'day',
        label: 'Yesterday'
    },
    {
        offset: 7,
        startOf: 'day',
        label: 'Last 7 days'
    },
    {
        offset: 30,
        startOf: 'day',
        label: 'Last 30 days'
    },
    {
        offset: 0,
        startOf: 'month',
        label: 'This month'
    },
    {
        offset: 1,
        startOf: 'month',
        label: 'Last month'
    },
    {
        offset: 0,
        startOf: 'year',
        label: 'This year'
    }
];

type FilterState = {
    page: number;
    rowsPerPage: number;
    dateOptionSelected: string;
    dateRange: [string | null, string | null];
};

const filterData = (arr: B2BCustomerGiftCardRedemption[], state: FilterState) => {
    // sort the orders by updated date so that latest orders are always on top
    const { rowsPerPage, page, dateRange } = state;
    let totalRecords = arr.length;

    // filter by date range
    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    const startDateInUTC = (startDate && moment.utc(startDate).local()) || null;
    const endDateInUTC = (endDate && moment.utc(endDate).local()) || null;
    arr = arr.filter((o) => {
        const orderCreatedAtInUTC = moment.utc(o.redeemedAt);
        return startDateInUTC && endDateInUTC && orderCreatedAtInUTC.isBetween(startDateInUTC, endDateInUTC);
    });

    totalRecords = arr.length;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate) {
        totalRecords = arr.length;
    }

    // order page limit
    arr = (rowsPerPage > 0 && arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || arr;

    return { arr, totalRecords };
};

type Props = {
    open: boolean;
    loading: boolean;
    redemptions: B2BCustomerGiftCardRedemption[];
    onDialogClose: () => void;
};

const GiftCardRedemptions = (props: Props) => {
    const classes = useStyles();

    const [filter, setFilter] = React.useState<FilterState>({
        page: 0,
        rowsPerPage: 5,
        dateOptionSelected: 'Last 30 days',
        dateRange: getDateRangeByOptionLabel(DATE_OPTIONS, 'Last 30 days')
    });

    const { arr: filteredRedemptions, totalRecords } = filterData(props.redemptions, filter);

    const rows: TableResultRow[] = filteredRedemptions.map((item, index) => {
        const data: TableResultRow = {
            id: {
                text: item.id.toString(),
                align: 'left'
            },
            redemptionId: {
                text: '#' + item.id.toString(),
                align: 'left'
            },
            amount: {
                text: '-' + formatDinero(getDinero(item.amount)),
                align: 'left'
            },
            balance: {
                text: formatDinero(generateDinero(item.balance)) || '',
                align: 'left'
            },
            redeemedAt: {
                text: formatTimeStamp(item.redeemedAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a) || '-',
                align: 'left'
            },
            notes: {
                text: item.notes || '-',
                element: <Typography className={classes.notes}>{item.notes || '-'}</Typography>,
                align: 'left'
            }
        };

        return data;
    });

    const handleChangePage = (page: number) => {
        setFilter((prevState) => ({ ...prevState, page }));
    };

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setFilter((prevState) => ({ ...prevState, page: 0, rowsPerPage }));
    };

    return (
        <Dialog onClose={props.onDialogClose} open={props.open} maxWidth="xl">
            <DialogTitle>
                Gift Card Redemptions{' '}
                <IconButton
                    aria-label="close"
                    onClick={props.onDialogClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box p={2}>
                <Box textAlign="right">
                    <FormControl>
                        <InputLabel id="redemption-date-filter">Show:</InputLabel>
                        <Select
                            labelId="redemption-date-filter"
                            value={filter.dateOptionSelected}
                            onChange={(e) => {
                                const dateOptionSelected = e.target.value as FilterState['dateOptionSelected'];
                                const dateRange = getDateRangeByOptionLabel(DATE_OPTIONS, dateOptionSelected);

                                setFilter((prevState) => ({
                                    ...prevState,
                                    dateOptionSelected,
                                    dateRange,
                                    page: 0
                                }));
                            }}
                        >
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <TableComponent
                    rowHover={false}
                    paperClass={classes.tablePaper}
                    showPaginator={{ bottom: true }}
                    rows={rows}
                    headCells={headCells}
                    keyField="id"
                    showCheckbox={false}
                    showToolbar={false}
                    showSearch={false}
                    isLoading={props.loading}
                    totalRecords={totalRecords}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Dialog>
    );
};

export default GiftCardRedemptions;
