import { BarcodeCode } from "../types";

export const getBarcodeDetails = (bardcodeCodes: BarcodeCode[] | null | undefined) => {
    if (!bardcodeCodes?.length) {
        return {};
    }
    const barcodeValue = bardcodeCodes[0]?.value;
    const barcodePin = bardcodeCodes[1]?.value;

    return {
        barcodeValue,
        barcodePin
    };
};
