export enum ProviderEnum {
    CARDMOOLA = 'cardmoola',
    GOOGLE = 'google',
    FACEBOOK = 'facebook'
}

export const AccountInactiveReasonEnum = [
    'REGISTRATION_PENDING',
    'ACCOUNT_TEMPORARILY_LOCKED',
    'RESET_PASSWORD',
    'SWITCH_TO_BUSINESS'
] as const;
export const UserRoleEnum = ['BUSINESS_USER', 'USER'] as const;

export const UserTypeEnum = ['B2B', 'B2C'] as const;

export const AccessStatusTypeEnum = [
    'VERIFICATION_PENDING',
    'APPROVAL_PENDING',
    'REVOKED',
    'ON_WATCH',
    'APPROVED',
] as const;

export const RegistrationOriginTypeEnum = ['CARDMOOLA', 'SHOPIFY'] as const;

export type PhotoAttributesType = {
    width: number;
    height: number;
    x: number;
    y: number;
    mimetype?: string | null;
    zoom?: number;
    rotation?: number;
    crop?: {
        x: number;
        y: number;
    };
};

export type UserAccount = {
    email?: string | null;
    provider?: ProviderEnum;
    taxId?: string | null;
    phone?: string | null;
    country?: string | null;
    companyName?: string | null;
    position?: string | null;
    userId?: number | null;
    providerId?: string | null;
    password?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    inActiveReason?: typeof AccountInactiveReasonEnum[number] | null;
    refreshToken?: string | null;
    noOfAttempts?: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastVisit?: string | null;
    otp?: number | null;
    otpCreatedAt?: string | null;
    role?: typeof UserRoleEnum[number] | null;
    photo?: string | null;
    photoAttributes?: PhotoAttributesType | null;
    accessStatus?: typeof AccessStatusTypeEnum[number] | null;
    accessUpdateReason?: string | null;
    fraudScore?: Record<string, { score: number }> | null;
    updatedBy?: Partial<UserAccount> | null;
    influencerUserId?: number | null;
    registrationOrigin?: typeof RegistrationOriginTypeEnum[number];
    checkFraud?: boolean;
};
