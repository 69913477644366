/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import SimpleSearch from '../../common/simple-search';
import { B2BGiftCardResponseType } from '../../../types/b2bGiftCard';
import {  mapGiftCardPriceDenominations } from '../../../actions/b2b-gift-card.action';
import storeContext from '../../../contexts/store-context';
import {
    DateFormatTypeEnum,
    capitalizeWords,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDateRangeByOptionLabel,
    getDinero
} from '../../../utilities';
import { manageUserEditFormUseStyles } from '../../manage-user/editForm';
import moment from 'moment';
import { DATE_OPTIONS, useGiftCardStyles } from '../sold-gift-cards';

const headCells: Array<HeadCell> = [
    { id: 'cardId', label: '#' },
    { id: 'encodedCardId', label: 'Encoded Card Id' },
    { id: 'name', label: 'Card Name' },
    { id: 'cardType', label: 'Card Type' },
    { id: 'expiryPeriod', label: 'Expiry Period' },
    { id: 'priceDenominations', label: 'Price Denominations' },
    { id: 'cardCreationFee', label: 'Over-Quota Card Fee' },
    { id: 'creationFeeDeductedDate', label: 'Creation Fee Deducted On' },
    { id: 'otherEcommercePlatforms', label: 'Ecommerce Platforms' },
    { id: 'createdAt', label: 'Created At' },
    { id: 'status', label: 'Status' }
];

const filterData = (arr: B2BGiftCardResponseType[], state: FilterState) => {
    arr = arr.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime());
    const { rowsPerPage, page, search, dateRange } = state;
    let totalMerchantCards = arr.length;

    // filter the cards for matching search text
    arr =
        (search &&
            arr.filter((i) => {
                return (
                    i.id?.toString().includes(search.toLowerCase()) ||
                    i.name?.toLowerCase().includes(search.toLowerCase()) ||
                    i.userId?.toString().includes(search.toLowerCase()) ||
                    i.cardType?.toLowerCase().includes(search.toLowerCase()) ||
                    i.priceDenominations?.toString().includes(search.toLowerCase()) ||
                    i.expiryPeriod?.toString().includes(search.toLowerCase()) ||
                    i.otherEcommercePlatforms?.includes(search.toLowerCase())
                );
            })) ||
        arr;

    // filter by date range
    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    const startDateInUTC = (startDate && moment.utc(startDate).local()) || null;
    const endDateInUTC = (endDate && moment.utc(endDate).local()) || null;
    arr = arr.filter((o) => {
        const orderCreatedAtInUTC = moment.utc(o.createdAt);
        return startDateInUTC && endDateInUTC && orderCreatedAtInUTC.isBetween(startDateInUTC, endDateInUTC);
    });

    totalMerchantCards = arr.length;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate || search) {
        totalMerchantCards = arr.length;
    }

    // order page limit
    arr = (rowsPerPage > 0 && arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || arr;

    return { arr, totalMerchantCards };
};

type FilterState = {
    search: string;
    page: number;
    rowsPerPage: number;
    dateOptionSelected: string;
    dateRange: [string | null, string | null];
};

const MerchantGiftCards = (props: { userId: number }) => {
    const { b2bGiftCardAction, manageBusinessUserAction } = storeContext();
    const userFormClasses = manageUserEditFormUseStyles();
    const classes = useGiftCardStyles();

    const [merchantGiftCards, setMerchantCards] = React.useState<B2BGiftCardResponseType[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState<FilterState>({
        page: 0,
        rowsPerPage: 5,
        search: '',
        dateOptionSelected: 'This year',
        dateRange: getDateRangeByOptionLabel(DATE_OPTIONS, 'This year')
    });

    const [totalCardCreationFee, setTotalFee] = React.useState(0);

    const { arr: filteredMerchantCards, totalMerchantCards } = filterData(merchantGiftCards, filter);

    const rows: TableResultRow[] = filteredMerchantCards
        .sort((a,b) => Number(b.isActive) - Number(a.isActive)) // sort the Active cards to be displayed first
        .map((item) => {
            const expiryPeriod = item.cardType === 'COMMERCIAL' ? `${item.expiryPeriod} months` : `${item?.expiryPeriod / 12} years`
            const data: TableResultRow = {
                id: {
                    text: item.id!.toString(),
                    align: 'left'
                },
                cardId: {
                    text: '#' + item.id!.toString(),
                    align: 'left'
                },
                encodedCardId: {
                    text: item.encodedCardId!,
                    align: 'left'
                },
                name: {
                    text: capitalizeWords(item.name || ''),
                    align: 'left'
                },
                cardType: {
                    text: item.cardType || '',
                    align: 'left'
                },
                expiryPeriod: {
                    text: expiryPeriod,
                    align: 'left'
                },
                priceDenominations: {
                    text: mapGiftCardPriceDenominations(item.priceDenominations) || '-',
                    align: 'left'
                },
                cardCreationFee: {
                    text: formatDinero(generateDinero(item.cardCreationFee ? item.cardCreationFee * 100 : 0)),
                    align: 'left'
                },
                creationFeeDeductedDate: {
                    text: item?.gcCreationFeeDeductedDate
                        ? formatTimeStamp(item?.gcCreationFeeDeductedDate, DateFormatTypeEnum.MMMM_D_YYYY) || '---'
                        : '---',
                    align: 'left'
                },
                otherEcommercePlatforms: {
                    text: item?.otherEcommercePlatforms?.join(', ') || '-',
                    align: 'left'
                },
                createdAt: {
                    text: (item.createdAt && formatTimeStamp(item.createdAt, DateFormatTypeEnum.MM_DD_YYYY_h_mm_a)) || '-',
                    align: 'left',
                    element: (
                        <Typography>
                            {formatTimeStamp(item.createdAt!, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                        </Typography>
                    )
                },
                status: {
                    text: item.isActive ? 'ACTIVE' : 'DEACTIVATED',
                    align: 'left'
                }
        };

        return data;
    });

    const init = () => {
        fetchMerchantGiftCards();
        fetchTotalCardCreationFee();
    };

    const fetchMerchantGiftCards = async () => {
        setLoading(true);
        const giftCards = await b2bGiftCardAction()?.getAllGiftCardsByMerchantId(props.userId);
        setMerchantCards(giftCards ? giftCards : []);
        setLoading(false);
    };

    const fetchTotalCardCreationFee = async () => {
        setLoading(true);
        const totalFee = await manageBusinessUserAction()?.getPendingCardCreationFeeByUserId(props.userId);
        setTotalFee(totalFee ? totalFee : 0);
        setLoading(false);
    };

    const handleChangePage = (page: number) => {
        setFilter((prevState) => ({ ...prevState, page }));
    };

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setFilter((prevState) => ({ ...prevState, page: 0, rowsPerPage }));
    };

    React.useEffect(() => {
        init();
    }, []);

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography className={userFormClasses.heading}>Merchant Gift Cards</Typography>
                    <Box>
                    <Typography className={userFormClasses.heading}>
                        Total Over-Quota Card Fee:{' '}
                        <Typography component="span" color="primary" className={userFormClasses.heading}>
                            {formatDinero(generateDinero(totalCardCreationFee))}
                        </Typography>
                    </Typography>
                    </Box>
                </Box>

                <Box my={2} display="flex" justifyContent="space-between">
                    <SimpleSearch
                        value={filter.search}
                        onChange={(val) =>
                            setFilter((prevState) => ({
                                ...prevState,
                                search: val
                            }))
                        }
                    />

                    <FormControl>
                        <InputLabel id="merchant-gift-cards-date-filter">Show:</InputLabel>
                        <Select
                            labelId="merchant-gift-cards-date-filter"
                            value={filter.dateOptionSelected}
                            onChange={(e) => {
                                const dateOptionSelected = e.target.value as FilterState['dateOptionSelected'];
                                const dateRange = getDateRangeByOptionLabel(DATE_OPTIONS, dateOptionSelected);

                                setFilter((prevState) => ({
                                    ...prevState,
                                    dateOptionSelected,
                                    dateRange,
                                    page: 0
                                }));
                            }}
                        >
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <TableComponent
                rowHover={false}
                paperClass={classes.tablePaper}
                showPaginator={{ bottom: true }}
                rows={rows}
                headCells={headCells}
                keyField="id"
                showCheckbox={false}
                showToolbar={false}
                showSearch={false}
                isLoading={isLoading}
                totalRecords={totalMerchantCards}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default MerchantGiftCards;
