import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, makeStyles, CircularProgress } from '@material-ui/core';
import { EGifterCardTypeEnum } from '../../types/savedProducts';
import storeContext from '../../contexts/store-context';
import { GeoLocationCountry } from '../../types';

const useStyles = makeStyles(() => ({
    filter: {
        width: 200,
        '& .MuiSelect-root': {
            textAlign: 'left'
        }
    }
}));

type Props = {
    giftCardType: typeof EGifterCardTypeEnum[number];
    countryCode: string;
    onGiftCardTypeChange: (giftCardType: Props['giftCardType']) => void;
    onCountryCodeChange: (giftCardType: Props['countryCode']) => void;
};

export const useCategoryFilter = () => {
    const [giftCardType, setGiftCardType] = React.useState<typeof EGifterCardTypeEnum[number]>('B2C');
    const [countryCode, setCountryCode] = React.useState('US');

    const onGiftCardTypeChange = (giftCardType: typeof EGifterCardTypeEnum[number]) => {
        setGiftCardType(giftCardType);
    };

    const onCountryCodeChange = (countryCode: string) => {
        setCountryCode(countryCode);
    };

    return {
        giftCardType,
        countryCode: giftCardType === 'B2C' ? 'US' : countryCode,
        onGiftCardTypeChange,
        onCountryCodeChange
    };
};

const CategoryFilter = (props: Props) => {
    const classes = useStyles();

    const { geoLocationAction } = storeContext();

    const [filteredGeoData, setFilteredGeoData] = React.useState<GeoLocationCountry[]>([]);

    const fetchFilteredGeoData = async () => {
        const newGeoData = await geoLocationAction()?.fetchFilteredGeoData();
        setFilteredGeoData(newGeoData || []);
    };

    React.useEffect(() => {
        fetchFilteredGeoData();
    }, []);

    return (
        <Box display="flex" justifyContent="flex-start" gridGap="4rem" mt={2} mb={4}>
            <FormControl variant="outlined" fullWidth className={classes.filter}>
                <InputLabel id="e-gifter-card-type-filter-label">Select Card Type:</InputLabel>
                <Select
                    labelId="select-e-gifter-card-type-filter"
                    value={props.giftCardType}
                    label="Select Card Type"
                    onChange={(e) => {
                        const cardType = e.target.value as typeof EGifterCardTypeEnum[number];
                        props.onGiftCardTypeChange(cardType);
                    }}
                >
                    {EGifterCardTypeEnum.map((type) => (
                        <MenuItem key={type} value={type}>
                            {`${type} EGifter Cards`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {props.giftCardType === 'B2B' && (
                <FormControl variant="outlined" fullWidth className={classes.filter}>
                    <InputLabel id="country-filter-label">Select Country:</InputLabel>
                    <Select
                        labelId="select-country-filter"
                        value={props.countryCode}
                        defaultValue={props.countryCode}
                        label="Select Country"
                        onChange={(e) => {
                            const country = e.target.value as string;
                            props.onCountryCodeChange(country);
                        }}
                    >
                        {filteredGeoData.map((geoData) => (
                            <MenuItem key={geoData.shortName} value={geoData.shortName}>
                                {geoData.name} ({geoData.shortName})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

export default CategoryFilter;
