import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, FormControl, Select, InputLabel, MenuItem, Button } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { InfluencerReferralPaymentAttributes, OrderSummary, UserAccount } from '../../types';
import { formatDinero, generateDinero, joinNameParts, scrollTop } from '../../utilities';
import { influencerReferralListUseStyles } from './referral-list.styles';
import storeContext from '../../contexts/store-context';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import useAdminStyles from '../styles';
import CachedIcon from '@material-ui/icons/Cached';

export const manageInfluencerReferralListUseStyles = makeStyles((theme) => ({
    form: {
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $innerForm': {
            marginTop: 36
        },
        '& $heading': {
            textAlign: 'left',
            fontSize: 20,
            fontWeight: 600,
            margin: '12px 0'
        },
        '& $footer': {
            width: '100%',
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    mainContainer: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    details: {
        textAlign: 'left'
    },
    staticFields: {
        display: 'flex',
        textAlign: 'left',
        '& span': {
            '&:first-child': {
                fontWeight: 900,
                marginRight: 12
            }
        },
        '& input': {
            padding: 0,
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            letterSpacing: '0.00938em'
        }
    },
    address: {
        borderRadius: 8,
        padding: 8,
        margin: '8px 0',
        boxShadow: theme.shadows[2],
        border: `1px solid ${theme.palette.primary.light}`
    },
    primary: {
        background: '#81c7844d',
        border: `1px solid ${theme.palette.success.light}`
    },
    phoneNumber: {
        fontSize: 16,
        fontWeight: 900,
        color: theme.palette.text.primary,
        '& label': {
            display: 'none'
        },
        '& input': {
            fontWeight: 900,
            padding: 8,
            textAlign: 'center',
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
                textAlign: 'left',
                paddingTop: 4,
                paddingLeft: 0,
                fontWeight: 400
            }
        },
        '& div *': {
            background: 'transparent !important',
            backgroundColor: 'transparent !important'
        },
        '& *:after, *:before': {
            display: 'none'
        }
    },
    cardRow: {
        marginTop: 20,
        marginBottom: 4,
        padding: 16,
        '&:first-child': {
            marginTop: 0,
            marginBottom: 6
        },
        '& $cardRowGrid': {
            '& > div': {
                textAlign: 'left',
                paddingBottom: 16,
                '&$cardRowGridPullRight': {
                    textAlign: 'right'
                },
                '&$cardRowGridPullCenter': {
                    textAlign: 'center'
                },
                [theme.breakpoints.up('sm')]: {
                    paddingBottom: 'unset',
                    '&$cardRowGridPullRight': {
                        textAlign: 'left'
                    },
                    '&$cardRowGridPullCenter': {
                        textAlign: 'left'
                    }
                }
            }
        },
        '& $actionButtonContainer': {
            textAlign: 'center',
            marginTop: 'unset',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right'
            },
            [theme.breakpoints.between('sm', 'lg')]: {
                marginTop: 6
            },
            '& button': {
                width: 175,
                color: 'rgba(0, 0, 0, 0.54)',
                [theme.breakpoints.up('lg')]: {
                    width: '100%'
                }
            }
        },
        '& h6': {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
            lineHeight: '12px',
            marginBottom: 4
        },
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '0.1px'
        }
    },
    cardRowGrid: {},
    cardRowGridPullRight: {},
    cardRowGridPullCenter: {},
    actionButtonContainer: {},
    innerForm: {},
    inner: {},
    heading: {},
    footer: {}
}));

const dateRanges = [
    { value: -1, label: 'All' },
    { value: 0, type: 'day', fromStart: false, label: 'Today' },
    { value: 7, type: 'day', fromStart: true, label: 'Last 7 days' },
    { value: 1, type: 'month', fromStart: true, label: 'Last 30 days' },
    { value: 1, type: 'month', fromStart: false, label: 'Last month' },
    { value: 3, type: 'month', fromStart: true, label: 'Last 3 months' },
    { value: 6, type: 'month', fromStart: true, label: 'Last 6 months' },
    { value: 1, type: 'year', fromStart: false, label: 'Last year' }
];

const filterReferralList = (users: Array<UserAccount & { orders: OrderSummary[] }>, state: State) => {
    const { searchText, selectedDateRange } = state;
    let newUsers: Array<UserAccount & { orders: OrderSummary[] }> = [];
    let totalRecords = users.length;

    const dateRange = dateRanges[selectedDateRange];

    // sort the orders by registration date so that latest registered users are always on top
    newUsers = users.sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime());

    // filter the users for matching search text
    newUsers =
        (searchText &&
            newUsers.filter((u) => {
                const emailMatch = `${u.email}`.indexOf(searchText.toLowerCase()) !== -1;
                if (emailMatch) {
                    return true;
                }

                const userIdMatch = `${u.userId}`.indexOf(searchText) !== -1;
                if (userIdMatch) {
                    return true;
                }

                return false;
            })) ||
        newUsers;

    // filter the users for matching date range
    newUsers = newUsers.filter((val) => {
        if (!dateRange.type) {
            return true;
        }

        const startDate = moment()
            .utc()
            .subtract((dateRange.value || 0) as moment.DurationInputArg1, dateRange.type as moment.DurationInputArg2)
            .startOf(dateRange.type as moment.unitOfTime.StartOf);

        const endDate = dateRange.fromStart
            ? moment().utc().endOf('day')
            : moment()
                .utc()
                .subtract(
                    (dateRange.value || 0) as moment.DurationInputArg1,
                    dateRange.type as moment.DurationInputArg2
                )
                .endOf(dateRange.type as moment.unitOfTime.StartOf);

        return moment.utc(val.createdAt).isBetween(startDate, endDate, undefined, '[]');
    });

    // if date filter props change, we adjust the total records
    if (selectedDateRange !== -1) {
        totalRecords = newUsers.length;
    }

    return { referralUsers: newUsers, totalRecords };
};

type Props = {
    influencer: Partial<UserAccount>;
    paymentConstant?: InfluencerReferralPaymentAttributes | null;
};

type State = {
    influencer: Partial<UserAccount> | null;
    expandedAccordion: string | null;
    selectedDateRange: number;
    selectedAccount: number;
    accountRange: 'ALL' | 'REALISED' | 'UNREALISED';
    searchText: string | null;
    isLoading: boolean;
};

const DEFAULT_STATE: State = {
    influencer: null,
    expandedAccordion: null,
    selectedDateRange: 0,
    selectedAccount: 0,
    accountRange: 'ALL',
    searchText: null,
    isLoading: true
};

const NULL_TEXT = 'unknown';

const InfluencerReferralList = (props: Props) => {
    const classes = manageInfluencerReferralListUseStyles();
    const referralClasses = influencerReferralListUseStyles();
    const adminClasses = useAdminStyles();

    const { manageInfluencerAction } = storeContext();

    const [state, setState] = useState<State>({ ...DEFAULT_STATE, influencer: props.influencer });

    const [referralUsersList, setReferralList] = useState<(UserAccount & { orders: OrderSummary[] })[]>([]);

    useEffect(() => {
        setState((prevState) => ({ ...prevState, influencer: props.influencer }));
    }, [props.influencer]);

    const headCells: Array<HeadCell> = [
        { id: 'userId', label: 'User ID' },
        { id: 'email', label: 'Email' },
        { id: 'orderBenefit', label: 'Order Benefit' },
        { id: 'registrationBenefit', label: 'Registration Benefit' },
        { id: 'registrationDate', label: 'Registration Date' }
    ];

    const accountRanges = [
        { key: 0, label: 'All' },
        { key: 1, label: 'Realised' },
        { key: 2, label: 'Unrealised' }
    ];

    useEffect(() => {
        fetchReferrals(props.influencer?.userId, null);
    }, [props.influencer?.userId]);

    const fetchReferrals = async (influencerId: number | null | undefined, realized: boolean | null) => {
        if (props.influencer?.userId) {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const referralUsers = await manageInfluencerAction()?.getReferralList(influencerId!, realized);
            setReferralList(referralUsers || []);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const { referralUsers, totalRecords } = filterReferralList(referralUsersList, state);

    const name = joinNameParts(state.influencer);

    const orderPayoutSettings =
        (props.paymentConstant?.influencerTransactionType &&
            props.paymentConstant?.influencerTransactionValue && {
            type: props.paymentConstant?.influencerTransactionType,
            amount: props.paymentConstant?.influencerTransactionValue
        }) ||
        null;

    const registrationPayoutSettings =
        (props.paymentConstant?.amount &&
            props.paymentConstant?.noOfRegistration && {
            type: 'AMOUNT',
            amount: props.paymentConstant?.amount || 0,
            count: props.paymentConstant?.noOfRegistration || 1
        }) ||
        null;

    const headerComponent = (
        <Grid container={true} direction="column" className={adminClasses.headerComponent}>
            <Grid item={true} xs={12}>
                <Box display="flex" justifyContent="space-between" width="100%" sx={{ mb: 4 }}>
                    <Typography className={adminClasses.tableHeading}>Benefits</Typography>

                    <Button onClick={() => fetchReferrals(props.influencer?.userId, null)} size="small" variant="contained" disabled={state.isLoading}>
                        <CachedIcon className={(state.isLoading && 'rotation-loading') || undefined} />
                        &nbsp;Refresh
                    </Button>
                </Box>
            </Grid>

            <Grid item={true} xs={12}>
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <FormControl variant="filled" className={referralClasses.picker}>
                        <InputLabel id="referral-list-date-filter">
                            <Typography color="textSecondary" component="span">
                                Date:
                            </Typography>
                        </InputLabel>
                        <Select
                            className={referralClasses.pickerSelect}
                            variant="standard"
                            labelId="referral-list-date-filter"
                            value={state.selectedDateRange}
                            onChange={(e) => {
                                const selectedDateRange = Number(e.target.value);
                                setState((prevState: State) => ({
                                    ...prevState,
                                    selectedDateRange
                                }));
                            }}
                        >
                            {dateRanges.map((d, i) => (
                                <MenuItem key={i} value={i}>
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" className={referralClasses.picker}>
                        <InputLabel id="referral-list-account-filter">
                            <Typography color="textSecondary" component="span">
                                Accounts:
                            </Typography>
                        </InputLabel>
                        <Select
                            className={referralClasses.pickerSelect}
                            variant="standard"
                            labelId="referral-list-account-filter"
                            value={state.selectedAccount}
                            onChange={(e) => {
                                const value = e.target.value;

                                setState((prevState) => ({
                                    ...prevState,
                                    selectedAccount: value as State['selectedAccount']
                                }));

                                fetchReferrals(state.influencer?.userId, value === 0 ? null : value === 1);
                            }}
                        >
                            {accountRanges.map((d) => (
                                <MenuItem key={d.key} value={d.key}>
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );

    const rows: Array<TableResultRow> = referralUsers.map((p) => {
        const orderDetail = (p.orders?.length && p.orders[0]) || null;
        const orderTotal = (orderDetail?.total && generateDinero(orderDetail?.total)) || null;

        const orderBenefit =
            (orderDetail &&
                orderPayoutSettings &&
                orderPayoutSettings.type === 'AMOUNT' &&
                formatDinero(generateDinero(orderPayoutSettings.amount * 100))) ||
            (orderDetail &&
                orderPayoutSettings &&
                orderPayoutSettings.type === 'PERCENTILE' &&
                orderTotal &&
                formatDinero(orderTotal.percentage(orderPayoutSettings.amount))) ||
            null;
        const registerationBenefit =
            (registrationPayoutSettings &&
                formatDinero(
                    generateDinero((registrationPayoutSettings.amount * 100) / registrationPayoutSettings.count)
                )) ||
            null;

        return {
            id: {
                align: 'left',
                text: p.userId?.toString() || ''
            },
            userId: {
                align: 'left',
                text: `#${p.userId?.toString() || ''}`
            },
            email: {
                align: 'left',
                text: p.email || ''
            },
            orderBenefit: {
                align: 'left',
                text: orderBenefit || ''
            },
            registrationBenefit: {
                align: 'left',
                text: registerationBenefit || ''
            },
            registrationDate: {
                align: 'left',
                text: moment(p.createdAt).format('MM/DD/YYYY') || ''
            }
        };
    });

    return (
        <Grid container={true} className={classes.inner}>
            <Grid item={true} xs={12} className={classes.form}>
                <Grid item xs={12}>
                    <Typography className={classes.heading}>Referrals</Typography>
                </Grid>
                <Grid item={true} xs={12} className={classes.details}>
                    <Grid item={true} xs={12} className={classes.staticFields}>
                        <Typography color="textSecondary" component="span">
                            Influencer User Id:
                        </Typography>
                        <Typography component="span">#{state.influencer?.userId}</Typography>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.staticFields}>
                        <Typography color="textSecondary" component="span">
                            Name:
                        </Typography>
                        <Typography component="span">{name}</Typography>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.staticFields}>
                        <Typography color="textSecondary" component="span">
                            Email:
                        </Typography>
                        <Typography component="span">{state.influencer?.email || NULL_TEXT}</Typography>
                    </Grid>
                </Grid>

                <br />

                <Grid item xs={12}>
                    <Box width="100%">
                        <TableComponent
                            rowHover={false}
                            headerComponent={headerComponent}
                            showPaginator={{ bottom: true }}
                            showSearch={true}
                            isLoading={state.isLoading}
                            rows={rows}
                            headCells={headCells}
                            fillEmptyRows={false}
                            keyField="id"
                            showCheckbox={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InfluencerReferralList;
