import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as Diff from 'diff';
import * as Diff2Html from 'diff2html';

import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import HasPermission from '../../utilities/can';
import storeContext from '../../contexts/store-context';
import { EGifterProduct } from '../../types/eGifter';
import { JSONstringifyOrder } from '../../utilities';
import { EGifterProductDump } from '../../types/eGifterDump';
import { ErrorType } from '../../types';
import { EGifterCardTypeEnum } from '../../types/savedProducts';
import CategoryFilter, { useCategoryFilter } from '../common/category-filter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 325
        }
    })
);

type State = {
    allProducts: EGifterProduct[];
    eGifterDump: EGifterProductDump[];
    /**
     * eGifter product id
     */
    rightSide: number;
    /**
     * eGifter product id
     */
    leftSide: number;
    dropdownOptions: {
        label: string;
        value: number;
    }[];
    isLoading: boolean;
    isButtonLoading: boolean;
};

class DropdownOption {
    label: string;
    value: number;

    constructor(d: EGifterProductDump) {
        this.label = `Saved Product - ${moment(d.createdAt).format('MM/DD/YYYY hh:mm A')}`;
        this.value = d.id;
    }
}

const CompareProduct = () => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, giftCardAction } = storeContext();

    const canRead = HasPermission(userService.user, 'COMPARE_PRODUCT', 'READ');
    const canCreate = HasPermission(userService.user, 'COMPARE_PRODUCT', 'CREATE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
    }, []);

    const [state, setState] = useState<State>({
        allProducts: [],
        eGifterDump: [],
        rightSide: -1, // we will use -1 to indiciate that it is products coming from eGifter API
        leftSide: -1, // we will use -1 to indiciate that it is products coming from eGifter API
        dropdownOptions: [{ label: 'EGifter Products', value: -1 }],
        isLoading: false,
        isButtonLoading: false
    });

    const { giftCardType, countryCode, onCountryCodeChange, onGiftCardTypeChange } = useCategoryFilter();

    useEffect(() => {
        getAllProducts(giftCardType, countryCode);
    }, [giftCardType, countryCode]);

    const fetchProductsById = (id: number): { name: string; data: EGifterProduct[] } => {
        const dropdownOption = state.dropdownOptions.find((d) => d.value === id);
        if (!dropdownOption) {
            throw new Error(`Could not find dropdown menu item with id ${id}`);
        }

        if (id === -1) {
            return {
                name: dropdownOption.label,
                data: state.allProducts
            };
        }

        const dumpedProducts = state.eGifterDump.find((d) => d.id === id);
        if (!dumpedProducts) {
            throw new Error(`Could not find product with id ${id}`);
        }
        return {
            name: dropdownOption.label,
            data: dumpedProducts.data
        };
    };

    let ds = '';
    const leftSideData = fetchProductsById(state.leftSide);
    const rightSideData = fetchProductsById(state.rightSide);

    (function makeJSONCompare() {
        try {
            const diff = Diff.createTwoFilesPatch(
                leftSideData.name,
                rightSideData.name,
                JSONstringifyOrder({ ...leftSideData.data }),
                JSONstringifyOrder({ ...rightSideData.data })
            );

            ds = Diff2Html.html(diff, {
                drawFileList: false,
                matching: 'lines',
                outputFormat: 'side-by-side'
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    })();

    const getAllProducts = async (giftCardType: typeof EGifterCardTypeEnum[number], countryCode: string) => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const [apiResponse, products] = await Promise.all([
                apiService.get(`/api/admin/egifter-dump/get-all?giftCardType=${giftCardType}&countryCode=${countryCode}`),
                giftCardType === 'B2C'
                    ? giftCardAction()?.getAllGiftCards({ showAll: true, forceFetch: true })
                    : giftCardAction()?.getAllB2BGiftCards({
                          showAll: true,
                          forceFetch: true,
                          cultureCode: `en-${countryCode}`
                      })
            ]);
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            const responseData = response.data as EGifterProductDump[];
            const latestSavedData = responseData.slice(-1);

            setState((prevState) => ({
                ...prevState,
                allProducts: products || [],
                eGifterDump: responseData || [],
                rightSide: -1,
                leftSide: latestSavedData[0]?.id || -1,
                dropdownOptions: [
                    { label: 'EGifter Products', value: -1 },
                    ...responseData.map((d) => new DropdownOption(d))
                ]
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const saveNewVersion = async () => {
        try {
            setState((prevState) => ({ ...prevState, isButtonLoading: true }));
            const apiResponse = await apiService.post(`/api/admin/egifter-dump/create`, {
                data: rightSideData.data,
                giftCardType,
                countryCode
            });
            setState((prevState) => ({ ...prevState, isButtonLoading: false }));

            const response = apiResponse.parsedBody;
            if (!response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return null;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });

            const updatedEGifterDump = [...state.eGifterDump, response.data as EGifterProductDump];

            setState((prevState) => ({
                ...prevState,
                allProducts: prevState.allProducts,
                eGifterDump: updatedEGifterDump,
                rightSide: prevState.rightSide,
                leftSide: response.data.id,
                dropdownOptions: [
                    { label: 'EGifter Products', value: -1 },
                    ...updatedEGifterDump.map((d) => new DropdownOption(d))
                ]
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isButtonLoading: false }));
        }
    };

    if (state.isLoading) {
        return (
            <Box my={4}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <Box my={4} textAlign="left">
                    <Card>
                        <Box p={2}>
                            <Typography variant="h1" gutterBottom>
                                <strong>Compare Products</strong>
                            </Typography>
                            <CategoryFilter
                                countryCode={countryCode}
                                giftCardType={giftCardType}
                                onCountryCodeChange={onCountryCodeChange}
                                onGiftCardTypeChange={onGiftCardTypeChange}
                            />
                            <Box display="flex" alignItems="center" flexWrap="wrap" mb={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="base-select-label">base:</InputLabel>
                                    <Select
                                        labelId="base-select-label"
                                        value={state.leftSide}
                                        label={leftSideData.name}
                                        onChange={(e) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                leftSide: e.target.value as number
                                            }))
                                        }
                                    >
                                        {state.dropdownOptions.map((d) => (
                                            <MenuItem key={d.value} value={d.value}>
                                                {d.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="base-select-label">compare:</InputLabel>
                                    <Select
                                        labelId="base-select-label"
                                        value={state.rightSide}
                                        label={rightSideData.name}
                                        onChange={(e) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                rightSide: e.target.value as number
                                            }))
                                        }
                                    >
                                        {state.dropdownOptions.map((d) => (
                                            <MenuItem key={d.value} value={d.value}>
                                                {d.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {canCreate && (
                                    <Box ml={'auto'}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={state.isButtonLoading}
                                            onClick={saveNewVersion}
                                        >
                                            Save as new version
                                            {state.isButtonLoading && <CircularProgress className="button-loader" />}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <Box overflow="auto">
                                <div dangerouslySetInnerHTML={{ __html: ds }} />
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Grid>
    );
};

export default CompareProduct;
