import { generateDinero, getDinero } from '.';
import { GiftCardAttributesCalculationEssential, OrderPayloadGiftCardProduct, OrderSummary } from '../types';
import { B2BOrderSummaryPayout } from '../types/b2bPayout';

const calculatePromoCodeDiscountForSubTotal = (promoCode: OrderSummary['promoCode'], subTotal: Dinero.Dinero) => {
    const promoCodeDiscount =
        (promoCode && promoCode.type === 'PERCENTILE' && subTotal.percentage(promoCode.value || 0)) ||
        (promoCode && promoCode.type === 'AMOUNT' && getDinero(promoCode.value)) ||
        generateDinero(0);

    // TODO check if amount promo should never be greater than cart value
    // TODO check if some max-amount can be applied
    const promoCodeMaxAmount =
        (promoCode && promoCode.maxAmount && getDinero(promoCode.maxAmount)) || promoCodeDiscount;

    return promoCodeDiscount.greaterThan(promoCodeMaxAmount) ? promoCodeMaxAmount : promoCodeDiscount;
};

const calculateOrderItemsTotal = (orderItems: GiftCardAttributesCalculationEssential[]) => {
    return orderItems.reduce((acc, c) => acc.add(generateDinero(c.price).multiply(c.quantity)), generateDinero(0));
};

/**
 * Returns the promo code discount amount for the promo code applied on the order items
 */
export const promoCodeCalculation = (
    cart: GiftCardAttributesCalculationEssential[],
    promoCode?: OrderSummary['promoCode']
) => {
    // compute promo code discount for b2b promocode
    const orderTotalB2BMatching = cart.filter(
        (c) => c.provider === 'CARDMOOLA' && c.encodedBusinessName === promoCode?.encodedBusinessName
    );

    // compute promo code discount for egifter promocode
    const orderTotalEGifterMatching = cart.filter((c) => c.provider === 'EGIFTER');

    if (promoCode?.encodedBusinessName) {
        const subTotal = calculateOrderItemsTotal(orderTotalB2BMatching);
        const promoCodeDiscount = calculatePromoCodeDiscountForSubTotal(promoCode, subTotal);
        return promoCodeDiscount;
    }

    const subTotal = calculateOrderItemsTotal(orderTotalEGifterMatching);
    const promoCodeDiscount = promoCode ? calculatePromoCodeDiscountForSubTotal(promoCode, subTotal) : getDinero(0);
    return promoCodeDiscount;
};

export const computeMerchantOrderPayoutDetails = (
    order?: B2BOrderSummaryPayout | null,
    orderItems: OrderPayloadGiftCardProduct[] = []
) => {
    const promoCodeDiscount = promoCodeCalculation(orderItems, order?.promoCode);

    const orderItemsTotal = calculateOrderItemsTotal(orderItems);

    const grandTotal = orderItemsTotal.subtract(promoCodeDiscount);

    const orderFee = orderItems.reduce((acc, cur) => {
        return acc.add(getDinero(order?.subscription?.orderFee || 0));
    }, getDinero(0));

    const paymentGatewayFee = generateDinero(order?.paymentGatewayFee || 0);

    const totalGiftCardFees = orderItems.reduce((acc, cur) => {
        return acc.add(generateDinero(cur.price).percentage(order?.subscription.giftCardFee || 0));
    }, getDinero(0));

    const amountToPay = grandTotal.subtract(orderFee).subtract(totalGiftCardFees).subtract(paymentGatewayFee);

    return {
        amountToPay,
        promoCodeDiscount,
        grandTotal,
        orderItemsTotal,
        orderFee,
        paymentGatewayFee,
        totalGiftCardFees
    };
};
