import { PaymentGatewayFeeConstantTypeEnum } from "./constants";
import { CashbackSlab } from "./eGifter";

export type BreakEvenCostType = Record<typeof PaymentGatewayFeeConstantTypeEnum[number], number>;
export const EGifterCardTypeEnum = ['B2C', 'B2B'] as const;
export type SavedProducts = {
    id: number;
    productId: string;
    name: string;
    giftCardType: typeof EGifterCardTypeEnum[number];
    countryCode: string;
    breakEvenCost: BreakEvenCostType;
    cashbackSlabs?: CashbackSlab;
    createdAt: Date;
    updatedAt: Date;
}
